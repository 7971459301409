<template>
  <div class="mx-auto w-50">
    <!-- Centered element -->
    <div class="vh-100 d-flex">
      <div class="d-flex w-100 justify-content-center align-self-center">
        <b-card
          class="w-100"
          title="Himawan Anindya Putra"
          sub-title="NIA: 123456"
        >
          <b-img v-if="fileQR" :src="fileQR" fluid alt="Fluid image')" />
          <b-img
            fluid
            class="mb-2"
            :src="require('@/assets/images/logo/peradi-v1.png')"
          />
          <b-card-text>
            Berlaku sampai dengan:
            <strong>31 Desember 2024</strong>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="mb-1"
              @click="downloadImage('161_1a4ce5896b23ad31aa58.jpg')"
            >
              <span class="align-middle">Download</span>
            </b-button>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
  <!-- <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card title="Himawan Anindya Putra" sub-title="NIA: 123456">
        <b-img
          fluid
          class="mb-2"
          :src="require('@/assets/images/logo/peradi-v1.png')"
        />
        <b-card-text>
          Berlaku sampai dengan:
          <strong>31 Desember 2024</strong>
        </b-card-text>
      </b-card>
    </div>
  </div> -->
</template>

<script>
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  // BLink,
  BImg,
  // BCardTitle,
  BCardText,
  // BForm,
  BButton,
  // BFormInput,
  // BFormGroup,
  // BInputGroup,
  // BInputGroupAppend,
  // BFormCheckbox,
  // BSpinner,
} from 'bootstrap-vue'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import { api, download } from '@/hap-sia/setup'
// import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    // VuexyLogo,
    // BSV
    BImg,
    BCard,
    // BLink,
    // BCardTitle,
    BCardText,
    // BForm,
    BButton,
    // BFormInput,
    // BFormGroup,
    // BInputGroup,
    // BInputGroupAppend,
    // BFormCheckbox,
    // validations
    // ValidationProvider,
    // ValidationObserver,
    // flatPickr,
    // BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      nia: null,
      fileQR: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async mounted() {
    try {
      // const result = await api().get('qr/code/161/data-url')
      const result = await api().get(
        'biodata/image/161/161_1a4ce5896b23ad31aa58.jpg',
      )
      console.log(result)
      this.fileQR = result.data.url
    } catch (error) {
      alert(error)
    }
  },
  methods: {
    async download(filename) {
      // alert(filename)
      try {
        const result = await download().get(`biodata/download/161/${filename}`)
        console.log(result)
        // this.fileQR = result.data.url
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename) // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        alert(error)
      }
    },
    async downloadImage(filename) {
      // alert(filename)
      try {
        const result = await download().get(`biodata/image/161/${filename}`)
        console.log(result)
      } catch (error) {
        alert(error)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
